import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import Table from "react-bootstrap/Table";
import "./App.css";
import * as XLSX from "xlsx";
import { ClipLoader } from "react-spinners"; // Import the loader
import logo from "./img/logo.svg"; // Import loga
import "./LoginScreen.css"; // Soubor s CSS pro snadnější úpravy stylů
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Zaregistrujeme potřebné komponenty
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DataTable = () => {
  const [data, setData] = useState([]);
  const [dataTabule, setDataTabule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [pevna_linka, setPevna_linka] = useState("NULL");
  const [kg_group, setKg_group] = useState("MB");
  const [year, setYear] = useState("2024");
  const [salesmanName, setSalesmanName] = useState("MB tým");
  const [showFinancniPlan, setShowFinancniPlan] = useState(true);
  const [showTabule, setShowTabule] = useState(false);

  const downloadExcel = () => {
    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheetData = [
      [
        "Product",
        "Description",
        "Leden",
        "Únor",
        "Březen",
        "Duben",
        "Květen",
        "Červen",
        "Červenec",
        "Srpen",
        "Září",
        "Říjen",
        "Listopad",
        "Prosinec",
      ],
      ...data.map((row) => [
        row.product,
        row.description,
        row.r202401,
        row.r202402,
        row.r202403,
        row.r202404,
        row.r202405,
        row.r202406,
        row.r202407,
        row.r202408,
        row.r202409,
        row.r202410,
        row.r202411,
        row.r202412,
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate an XLSX file and trigger download
    XLSX.writeFile(workbook, "data.xlsx");
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        const email = userInfoResponse.data.email;
        if (
          email === "jindrich.patera@gmail.com" ||
          email === "marketa.buresova@bhs.cz" ||
          email === "karel.vodicka@bhs.cz" ||
          email === "tomas.patera@pnona.cz" ||
          email === "lepka@bhs.cz" ||
          email === "zdenek.lepka@bhs.cz"
        ) {
          setToken(response.access_token);
          setUserEmail(email);
        } else {
          setError("Access denied: Unauthorized email address");
        }
      } catch (err) {
        setError("Error fetching user info");
      }
    },
    onError: () => setError("Error logging in with Google"),
    scope: "profile email",
  });

  const LoginScreen = () => {
    return (
      <div className="login-screen">
        <div className="login-container">
          {/* Logo */}
          <img src={logo} alt="BH Securities Logo" className="logo" />

          {/* Textové uvítání */}
          <h2 className="welcome-text">Obchodní statistiky</h2>

          {/* Obrázek s tematikou salesu */}
          <div className="sales-image">
            <img
              src={require("./img/sales-image4.webp")}
              alt="Telefonování a uzavírání kontraktů"
            />
          </div>

          {/* Tlačítko Login with Google */}
          <button className="login-button" onClick={() => login()}>
            Přihlásit se
          </button>
        </div>
      </div>
    );
  };

  const fetchDataTabule = useCallback(async () => {
    if (!token) return;

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        "https://wandering-leaf-0f43.jindrich-patera.workers.dev/WebService.asmx/GetData?procedure=p_wsl_hc_GetBHSVypoctyTabule&parameters=<Parameters><Parameter Name='name_user' Value='" +
          userEmail +
          "' /></Parameters>",
        {
          headers: {
            "Content-Type": "application/xml",
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const decodedString = new DOMParser().parseFromString(
        response.data,
        "text/html"
      ).documentElement.textContent;
      const parser = new DOMParser();
      const xml = parser.parseFromString(decodedString, "text/xml");
      const rows = Array.from(xml.getElementsByTagName("Row"));

      setDataTabule(
        rows.map((row) => {
          const getValue = (tagName) => {
            const element = row.getElementsByTagName(tagName)[0];
            return element ? element.textContent : "";
          };

          const dataEntries = Array.from(row.children)
            .filter((child) => child.tagName.match(/^_x0032_\d{4}$/))
            .reduce((acc, child) => {
              acc[child.tagName] = child.textContent;
              return acc;
            }, {});

          return {
            product: getValue("product"),
            description: getValue("description"),
            //kc_group: getValue("kc_group"),
            //ID_crm_osoba: getValue("ID_crm_osoba"),
            //salesman: getValue("salesman"),
            //src: getValue("src"),
            r202401: getValue("r202401"),
            r202402: getValue("r202402"),
            r202403: getValue("r202403"),
            r202404: getValue("r202404"),
            r202405: getValue("r202405"),
            r202406: getValue("r202406"),
            r202407: getValue("r202407"),
            r202408: getValue("r202408"),
            r202409: getValue("r202409"),
            r202410: getValue("r202410"),
            r202411: getValue("r202411"),
            r202412: getValue("r202412"),
            ...dataEntries,
          };
        })
      );
    } catch (err) {
      setError(`Error fetching data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [token, userEmail]);

  const fetchData = useCallback(async () => {
    if (!token) return;

    setLoading(true);
    setError(null);

    // https://wandering-leaf-0f43.jindrich-patera.workers.dev/WebService.asmx/GetData?procedure=p_wsl_hc_salesman&parameters=<Parameters><Parameter Name='kc_group' Value='MB' /><Parameter Name='pevna_linka' Value='NULL' /> <Parameter Name='name_user' Value = '' /> <Parameter Name='year' Value = '2024' /></Parameters>

    try {
      const response = await axios.get(
        "https://wandering-leaf-0f43.jindrich-patera.workers.dev/WebService.asmx/GetData?procedure=p_wsl_hc_salesman&parameters=<Parameters><Parameter Name='kc_group' Value='" +
          kg_group +
          "' /><Parameter Name='pevna_linka' Value='" +
          pevna_linka +
          "' /> <Parameter Name='name_user' Value='" +
          userEmail +
          "' /> <Parameter Name='year' Value='" +
          year.toString() +
          "' /></Parameters>",
        {
          headers: {
            "Content-Type": "application/xml",
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const decodedString = new DOMParser().parseFromString(
        response.data,
        "text/html"
      ).documentElement.textContent;
      const parser = new DOMParser();
      const xml = parser.parseFromString(decodedString, "text/xml");
      const rows = Array.from(xml.getElementsByTagName("Row"));

      setData(
        rows.map((row) => {
          const getValue = (tagName) => {
            const element = row.getElementsByTagName(tagName)[0];
            return element ? element.textContent : "";
          };

          const dataEntries = Array.from(row.children)
            .filter((child) => child.tagName.match(/^_x0032_\d{4}$/))
            .reduce((acc, child) => {
              acc[child.tagName] = child.textContent;
              return acc;
            }, {});

          return {
            product: getValue("product"),
            description: getValue("description"),
            //kc_group: getValue("kc_group"),
            //ID_crm_osoba: getValue("ID_crm_osoba"),
            //salesman: getValue("salesman"),
            //src: getValue("src"),
            r202401: getValue("r202401"),
            r202402: getValue("r202402"),
            r202403: getValue("r202403"),
            r202404: getValue("r202404"),
            r202405: getValue("r202405"),
            r202406: getValue("r202406"),
            r202407: getValue("r202407"),
            r202408: getValue("r202408"),
            r202409: getValue("r202409"),
            r202410: getValue("r202410"),
            r202411: getValue("r202411"),
            r202412: getValue("r202412"),
            Celkem: getValue("Celkem"),
            ...dataEntries,
          };
        })
      );
    } catch (err) {
      setError(`Error fetching data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [token, kg_group, pevna_linka, userEmail, year]);

  useEffect(() => {
    fetchData();
    fetchDataTabule();
  }, [fetchData, fetchDataTabule]);

  if (!token) {
    //return <button onClick={() => login()}>Login with Google</button>;
    return <LoginScreen />;
  }

  /*   if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>; */

  // Replace the loading text with a loader
  /* if (loading) {
    return (
      <div className="loader-overlay">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  } */
  if (error) return <p>{error}</p>;

  const yearColumns =
    data.length > 0
      ? Object.keys(data[0]).filter((key) => key.match(/^_x0032_\d{4}$/))
      : [];

  function formatNumberWithSpaces(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function handleYearChange(in_year) {
    if (in_year === year) {
      return;
    }

    setYear(in_year);
    setData([]);
  }

  function handlePevnaLinkaChange(in_pevna_linka) {
    if (in_pevna_linka === pevna_linka) {
      return;
    }

    if (in_pevna_linka === "MB" && salesmanName === "MB tým") {
      return;
    }

    if (in_pevna_linka === "ZL" && salesmanName === "ZL tým") {
      return;
    }

    setData([]);
    setPevna_linka(in_pevna_linka);

    switch (in_pevna_linka) {
      case "MB":
        setSalesmanName("MB tým");
        setKg_group("MB");
        setPevna_linka("NULL");
        break;
      case "ZL":
        setSalesmanName("ZL tým");
        setKg_group("ZL");
        setPevna_linka("NULL");
        break;
      case "255710771":
        setSalesmanName("Česenek");
        setKg_group("MB");
        break;
      case "255710741":
        setSalesmanName("Hrkal");
        setKg_group("MB");
        break;
      case "255710795":
        setSalesmanName("Pešek");
        setKg_group("MB");
        break;
      case "255710778":
        setSalesmanName("Lukáč");
        setKg_group("MB");
        break;
      case "255710734":
        setSalesmanName("Pánek");
        setKg_group("MB");
        break;
      case "255710774":
        setSalesmanName("Podešva");
        setKg_group("MB");
        break;
      case "255710792":
        setSalesmanName("Růžička");
        setKg_group("MB");
        break;
      case "255710776":
        setSalesmanName("Horák");
        setKg_group("ZL");
        break;
      case "255710761":
        setSalesmanName("Pirich");
        setKg_group("ZL");
        break;
      case "255710746":
        setSalesmanName("Resch");
        setKg_group("ZL");
        break;
      case "255710780":
        setSalesmanName("Stark");
        setKg_group("ZL");
        break;
      case "255710784":
        setSalesmanName("Červený");
        setKg_group("ZL");
        break;
      case "255710755":
        setSalesmanName("Žilinčárová");
        setKg_group("ZL");
        break;
      default:
        setSalesmanName("Unknown");
    }
  }

  const Tabule = () => {
    return (
      <div>
        <h1>Tabule</h1>

        <div className="icon-container">
          <button onClick={downloadExcel}>📅 Download Excel</button>
        </div>

        <Table striped bordered hover variant="dark">
          <thead>
            <tr align="center">{/* <th colSpan="14">{salesmanName}</th> */}</tr>
            <tr>
              <th>Product</th>
              <th>Leden</th>
              <th>Únor</th>
              <th>Březen</th>
              <th>Duben</th>
              <th>Květen</th>
              <th>Červen</th>
              <th>Červenec</th>
              <th>Srpen</th>
              <th>Září</th>
              <th>Říjen</th>
              <th>Listopad</th>
              <th>Prosinec</th>
              {yearColumns.map((year) => (
                <th key={year}>{year.replace("_x0032_", "Year ")}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTabule.map((row, index) => (
              <tr key={index}>
                <td
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {row.product}
                </td>

                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202401)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202402)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202403)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202404)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202405)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202406)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202407)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202408)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202409)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202410)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202411)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202412)}
                </td>
                {yearColumns.map((year) => (
                  <td key={year}>{row[year]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const PlneniFinancnihoPlanu = () => {
    // Filtrujeme data pro produkty "Celkem" a "Plán"
    const filteredData = data.filter(
      (row) => row.product === "Celkem" || row.product === "Plán"
    );

    // Konfigurace dat a možností pro graf
    const chartData = {
      labels: [
        "Leden",
        "Únor",
        "Březen",
        "Duben",
        "Květen",
        "Červen",
        "Červenec",
        "Srpen",
        "Září",
        "Říjen",
        "Listopad",
        "Prosinec",
      ],
      datasets: filteredData.map((row) => ({
        label: row.product,
        data: [
          row.r202401,
          row.r202402,
          row.r202403,
          row.r202404,
          row.r202405,
          row.r202406,
          row.r202407,
          row.r202408,
          row.r202409,
          row.r202410,
          row.r202411,
          row.r202412,
        ].map((value) => parseFloat(value) || 0), // Převod hodnot na čísla
        borderColor:
          row.product === "Celkem"
            ? "rgba(0, 255, 0, 1)"
            : "rgba(200, 200, 200, 1)",
        backgroundColor:
          row.product === "Celkem"
            ? "rgba(0, 255, 0, 1)"
            : "rgba(200, 200, 200, 1)",
        borderWidth: row.product === "Plán" ? 1 : 5,
        borderDash: row.product === "Plán" ? [5, 5] : [],
        fill: false,
      })),
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
          labels: {
            color: "white", // Barva textu legendy
            font: {
              size: 14, // Velikost textu legendy
            },
          },
        },
        title: {
          display: true,
          /* text: "Plnění finančního plánu 2024 (" + salesmanName + ")", */
          text: "Plnění finančního plánu " + year + " (" + salesmanName + ")",
          color: "white", // Barva textu nad grafem
          font: {
            size: 18,
            weight: "bold",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "white", // Barva textu na ose x
          },
          grid: {
            color: "rgba(255, 0, 0, 0.2)", // Barva mřížky na ose x
          },
        },
        y: {
          ticks: {
            color: "white", // Barva textu na ose y
          },
          grid: {
            color: "rgba(0, 0, 255, 0.2)", // Barva mřížky na ose y
          },
        },
      },
    };

    function getTooltipText(product, description) {
      //return `${row.product}: ${row.description}`;

      if (
        product === "Zlato jednoráz" &&
        description === "odměna salesmanovi"
      ) {
        return "Udává provizi makléřovi z prodaných jednorázů. Počítá se jako 25 % z řádku [Zlato - jednoráz]. Přičemž se bere v potaz tržní cena zlata v momentě prodeje cihly klientovi.";
      }

      if (product === "Zlato mince" && description === "odměna salesmanovi") {
        return "Udává provizi makléřovi z prodaných zlatých mincí. Počítá se buď absolutně nebo relativně. Koeficienty jsou v příznaku ceniny. V případě relativního plnění se bere v potaz i fakturační cena, která je zadána v kurzech (typ kurzu 301 - Kurz zlata mince dle faktury - nákup)";
      }

      if (product === "FKI - MFEE" && description === "odměna salesmanovi") {
        return "Udává provizi makléřovi. Počítá se jako 20 % z celkového výnosu z [FKI - MFEE].";
      }

      if (product === "FKI" && description === "MFEE") {
        return "Vezmou se pozice v portfoliu klientů salesmana, přičemž jde o typy portfolia 33 (BHS správa aktiv) a 1212 (Versute) a zároveň se uplatní pouze FKI ceniny (takové které mají příznak 1112 - Fond -popis). Objem se vynásobí koeficientem z příznaku ceniny [1118 - Fond - MFEE koeficient].";
      }

      if (product === "FKI" && description === "Poplatek klienta (nákup)") {
        return "Vezme FKI nákupní pokyny a jejich objem pronásobí koeficientem, který je dán příznakem ceniny (1117 - Fond - provize) v systému Broadway.";
      }

      if (
        product === "FKI - nákupní pokyny" &&
        description === "odměna salesmanovi"
      ) {
        return "Udává provizi makléřovi.  Takže zde je přenesena hodnota z pole [Poplatek klienta (nákup)]";
      }

      if (
        product === "Asset - Provize z MFEE" &&
        description === "odměna salesmanovi"
      ) {
        return "Udává provizi makléřovi. Počítá se jako 20 % z celkového výnosu z [Správa aktiv (MFEE)].";
      }

      if (
        product === "Asset - Provize z vkladů" &&
        description === "odměna salesmanovi"
      ) {
        return "Udává provizi makléřovi. Počítá se jako jedna dvanáctina z 0.9 % z celkových vkladů a výběrů. Tj. jde o vzorec [Správa aktiv - vklady a výběry kumulovaně celkem] * 0.009 / 12.";
      }

      if (description === "Správa aktiv (MFEE)") {
        return "Teto řádek udává, kolik peněz získala společnost BHS v podobě poplatku za správu v daném měsíci z klientů daného makléře";
      }

      if (description === "Správa aktiv - vklady") {
        return "Teto řádek udává, kolik prostředků v daném měsíci klienti daného makléře vložili do společnosti BHS nových vkladů";
      }

      if (description === "Správa aktiv - vklady kumulovaně") {
        return "Teto řádek udává, kolik prostředků za posledních 12 měsíců klienti daného makléře vložili do společnosti BHS nových vkladů";
      }

      if (description === "Správa aktiv - výběry") {
        return "Teto řádek udává, kolik prostředků v daném měsíci klienti daného makléře vybrali ze společnosti BHS v podobě výběrů.";
      }

      if (description === "Správa aktiv - výběry kumulovaně") {
        return "Teto řádek udává, kolik prostředků za posledních 12 měsíců klienti daného makléře vybrali ze společnosti BHS v podobě výběrů";
      }

      if (description === "Správa aktiv - vklady a výběry kumulovaně celkem") {
        return "Teto řádek udává, kolik prostředků za posledních 12 měsíců klienti daného makléře vložili do společnosti BHS nových vkladů a kolik prostředků za posledních 12 měsíců klienti daného makléře vybrali ze společnosti BHS v podobě výběrů";
      }

      if (description === "Správa aktiv - vklady a výběry kumulovaně celkem") {
        return "Teto řádek udává, kolik prostředků za posledních 12 měsíců klienti daného makléře vložili do společnosti BHS nových vkladů a kolik prostředků za posledních 12 měsíců klienti daného makléře vybrali ze společnosti BHS v podobě výběrů";
      }

      return "... zatím bez popisu ...";
    }

    return (
      <div>
        <h1>Plnění finančního plánu roku {year} </h1>
        <div className="year-buttons">
          <nav className="nav-buttons">
            <button onClick={() => handleYearChange(2024)}>2024</button>
            <button onClick={() => handleYearChange(2025)}>2025</button>

            <button disabled>2026</button>
            <button disabled>2027</button>
          </nav>
        </div>

        <div className="filter-area">
          <nav className="nav-buttons">
            <button
              onClick={() => handlePevnaLinkaChange("MB")}
              class={
                salesmanName === "MB tým"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
            >
              MB tým
            </button>

            <button
              onClick={() => handlePevnaLinkaChange("255710771")}
              class={
                salesmanName === "Česenek"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
            >
              Česenek
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710741")}
              class={
                salesmanName === "Hrkal" ? "btn btn-success" : "btn btn-primary"
              }
            >
              Hrkal
            </button>

            <button
              onClick={() => handlePevnaLinkaChange("255710795")}
              class={
                salesmanName === "Pešek" ? "btn btn-success" : "btn btn-primary"
              }
            >
              Pešek
            </button>

            <button
              onClick={() => handlePevnaLinkaChange("255710778")}
              class={
                salesmanName === "Lukáč" ? "btn btn-success" : "btn btn-primary"
              }
            >
              Lukáč
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710734")}
              class={
                salesmanName === "Pánek" ? "btn btn-success" : "btn btn-primary"
              }
            >
              Pánek
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710774")}
              class={
                salesmanName === "Podešva"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
            >
              Podešva
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710792")}
              class={
                salesmanName === "Růžička"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
            >
              Růžička
            </button>
          </nav>
        </div>

        <div className="filter-area">
          <nav className="nav-buttons">
            <button
              onClick={() => handlePevnaLinkaChange("ZL")}
              class={
                salesmanName === "ZL tým"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
            >
              ZL tým
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710776")}
              class={
                salesmanName === "Horák" ? "btn btn-success" : "btn btn-primary"
              }
            >
              Horák
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710761")}
              class={
                salesmanName === "Pirich"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
            >
              Pirich
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710746")}
              class={
                salesmanName === "Resch" ? "btn btn-success" : "btn btn-primary"
              }
            >
              Resch
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710780")}
              class={
                salesmanName === "Stark" ? "btn btn-success" : "btn btn-primary"
              }
            >
              Stark
            </button>
            <button
              onClick={() => handlePevnaLinkaChange("255710784")}
              class={
                salesmanName === "Červený"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
            >
              Červený
            </button>

            {/* <button
              onClick={() => handlePevnaLinkaChange("255710755")}
              class={
                salesmanName === "Žilinčárová"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
            >
              Žilinčárová
            </button> */}
          </nav>
        </div>

        <div className="icon-container">
          <button onClick={downloadExcel}>📅 Download Excel</button>
        </div>

        <Table striped bordered hover variant="dark">
          <thead>
            <tr align="center">
              <th colSpan="15" class="table-success">
                {/* {salesmanName} ({kg_group}) */}
              </th>
            </tr>
            <tr>
              <th>Product</th>
              <th>Description</th>
              <th>Leden</th>
              <th>Únor</th>
              <th>Březen</th>
              <th>Duben</th>
              <th>Květen</th>
              <th>Červen</th>
              <th>Červenec</th>
              <th>Srpen</th>
              <th>Září</th>
              <th>Říjen</th>
              <th>Listopad</th>
              <th>Prosinec</th>
              <th>Celkem</th>
              {yearColumns.map((year) => (
                <th key={year}>{year.replace("_x0032_", "Year ")}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={index}
                data-toggle="tooltip"
                /* title={"Some tooltip text! " + row.product} */
                title={getTooltipText(row.product, row.description)}
              >
                <td
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {row.product}
                </td>
                <td
                  class={
                    row.product === "Celkem" ? "table-success" : "table-default"
                  }
                >
                  {row.description}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202401 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202401)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202402 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202402)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202403 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202403)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202404 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202404)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202405 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202405)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202406 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202406)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202407 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202407)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202408 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202408)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202409 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202409)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202410 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202410)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202411 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202411)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.r202412 < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.r202412)}
                </td>
                <td
                  align="right"
                  class={
                    row.product === "Celkem"
                      ? "table-success"
                      : row.product === "Plnění plánu" && row.Celkem < 100
                      ? "table-danger"
                      : "table-default"
                  }
                >
                  {formatNumberWithSpaces(row.Celkem)}
                </td>
                {yearColumns.map((year) => (
                  <td key={year}>{row[year]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="chart-container">
          <Line data={chartData} options={options} />
        </div>
      </div>
    );
  };

  const handleButtonPlneniFinancnihoPlanu = () => {
    //setShowFinancniPlan(!showFinancniPlan);
    setShowFinancniPlan(true);
    setShowTabule(false);
  };

  const hadleButtonTabule = () => {
    setShowFinancniPlan(false);
    //setShowTabule(!showTabule);
    setShowTabule(true);
  };

  return (
    <div className="dark-background">
      <div className="header">
        <img src={logo} alt="BH Securities Logo" className="logo" />
        <p className="user-info">Logged in as: {userEmail}</p>
      </div>
      <nav>
        <button onClick={handleButtonPlneniFinancnihoPlanu}>
          Plnění finančního plánu
        </button>
        <button onClick={hadleButtonTabule}>Tabule (Loads)</button>
        <a
          href="https://docs.google.com/document/d/13fCbZPcIkyFwjCmtIhcxPXF5Af9sp5b8LGn2OCAxkhc/edit?tab=t.0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Manuál</button>
        </a>
      </nav>
      {/* {showFinancniPlan && <PlneniFinancnihoPlanu />}
      {showTabule && <Tabule />} */}
      <div className="data-container">
        {/* Display loader as an overlay */}
        {loading && (
          <div className="loader-overlay">
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
          </div>
        )}

        {/* Render table content even when loading */}
        {error ? (
          <p>{error}</p>
        ) : (
          <div className="content">
            {showFinancniPlan && <PlneniFinancnihoPlanu />}
            {showTabule && <Tabule />}
          </div>
        )}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <GoogleOAuthProvider clientId="979417741730-glsnsospfs1evsfngddb6a5jsmt5ioku.apps.googleusercontent.com">
      <DataTable />
    </GoogleOAuthProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

export default App;
